import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { currencyFormatter, handleError, valueToNull } from "_helpers";
import { OrdersService } from "_services";
import {
  selectActiveOrderId,
  selectFeeMapByProductCode,
  selectFeesLoading,
  selectMainProduct,
} from "_store/orders/selector";
import { calculateFees, getOrder } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import Icon from "components/atomic/atoms/Icon";
import { SpinnerBlock } from "components/atomic/atoms/Spinner";
import LoadingWrapper from "components/atomic/molecules/LoadingWrapper";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const StyledBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Row = styled.div`
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
`;

const ErrorText = styled.div`
  font-weight: bold;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-danger"]};
  `}
`;

const ErrorMessage = styled.div`
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 0 4px gray inset;
  font-family: monospace;
  word-wrap: break-word;
`;

const StyledButton = styled(Button)`
  min-width: 80px;

  &:last-child {
    margin-left: auto;
  }
`;

const STEPS = {
  init: "step-init",
  start: "step-start",
  loading: "step-loading",
  success: "step-success",
  error: "step-error",
};

const EinTaxIdUpsell = ({ upsellCode, onCancel }) => {
  const dispatch = useDispatch();

  const orderId = useSelector(selectActiveOrderId);
  const customerId = useSelector(selectCustomerId);
  const mainProduct = useSelector(selectMainProduct);
  const stateFeeLoading = useSelector(selectFeesLoading);
  const basicPriceObj = useSelector(selectFeeMapByProductCode(upsellCode));

  const [step, setStep] = useState(STEPS.start);
  const [error, setError] = useState(null);

  const processingOption = "STANDARD";
  const basicPrice = basicPriceObj[processingOption] || 0;
  const isCanCharge = !stateFeeLoading && basicPrice > 0;

  function renderPrice(price) {
    return (
      <LoadingWrapper loading={stateFeeLoading}>
        <b>{currencyFormatter(price)}</b>
      </LoadingWrapper>
    );
  }

  function handleCloseModal({ update = false }) {
    setError(null);
    setStep(STEPS.init);
    onCancel();
    update && dispatch(getOrder({ orderId, customerId, refreshOrders: true }));
  }

  async function createUpsell() {
    try {
      setStep(STEPS.loading);

      const body = {
        order: { processingOption, campaignId: 28 },
        products: [
          {
            code: upsellCode,
            legalName: mainProduct?.companyName || null,
            organizedState: mainProduct?.organizedState || null,
            numberOfMembers: mainProduct?.members?.length || 1,
            owner: mainProduct?.owner
              ? {
                  firstName: mainProduct?.owner?.firstName || null,
                  lastName: mainProduct?.owner?.lastName || null,
                  middleName: mainProduct?.owner?.middleName || null,
                  prevFirstName: mainProduct?.owner?.prevFirstName || null,
                  prevLastName: mainProduct?.owner?.prevLastName || null,
                  ssn: valueToNull(mainProduct?.owner?.ssn),
                  suffixName: mainProduct?.owner?.suffixName || null,
                  title: mainProduct?.owner?.title || null,
                }
              : null,
            corpAddress: mainProduct?.corporateAddress || null,
            activityPrimaryActivity: mainProduct?.activityPrimaryActivity || null,
            activitySpecificProducts: mainProduct?.activitySpecificProducts || null,
            productDescription: mainProduct?.productDescription || null,
            productExtraQuestion1: mainProduct?.productExtraQuestion1 || null,
            productExtraQuestion2: mainProduct?.productExtraQuestion2 || null,
            productExtraQuestion3: mainProduct?.productExtraQuestion3 || null,
          },
        ],
      };

      const request = await OrdersService.createDelayedUpsell({ orderId, body });

      if (request?.status === 200) {
        if (request?.data?.products[0]?.paymentStatus === "Declined") {
          setError({
            message: "Uh oh - your card on file has been declined. Please update your payment method to proceed",
          });
          setStep(STEPS.error);
        } else {
          setStep(STEPS.success);
        }
      } else {
        setStep(STEPS.error);
        setError({ message: "Unknown Error" });
      }
    } catch (response) {
      setStep(STEPS.error);
      setError(handleError(response));
    }
  }

  useEffect(() => {
    dispatch(
      calculateFees({
        codes: [upsellCode],
        processingOption,
      })
    );
  }, [dispatch]);

  switch (step) {
    case STEPS.start:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: false })}>
          <Modal.Header closeButton />
          <Modal.Body>
            <Row className="py-4">
              Would you like to purchase our EIN/Tax ID registration services for {renderPrice(basicPrice)}?
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <StyledButton variant="primary" disabled={!isCanCharge} onClick={createUpsell}>
              Yes
            </StyledButton>
            <StyledButton variant="secondary" onClick={() => handleCloseModal({ update: false })}>
              No
            </StyledButton>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.loading:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => {}}>
          <StyledBody>
            <SpinnerBlock message="Sending Request..." />
          </StyledBody>
        </Modal>
      );

    case STEPS.success:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: true })}>
          <StyledHeader closeButton></StyledHeader>
          <StyledBody>
            <Row className="py-4">
              <Icon inline icon="misc:success-check" size="32px" color="#32D583" />
              &nbsp;Thank you! Your EIN/Tax ID purchase was successful!
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.error:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: true })}>
          <StyledHeader closeButton></StyledHeader>
          <StyledBody>
            <Row className="py-4">
              <ErrorText>
                <Icon inline icon="error:alert" size="24px" />
                &nbsp;Error:
              </ErrorText>
              <ErrorMessage>{error?.message || "Something went wrong..."}</ErrorMessage>
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    default:
      return null;
  }
};

EinTaxIdUpsell.propTypes = {
  upsellCode: PropTypes.string,
  onCancel: PropTypes.func,
};

export default EinTaxIdUpsell;
