import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";

import { PRODUCT_CODES } from "_constants/products";
import { handleProductName } from "_helpers";
import { selectPaymentMethodsLoading } from "_store/billing/selector";
import { getPaymentMethods } from "_store/billing/slice";
import {
  selectActionRequiredProducts,
  selectActiveOrderRelatedCodes,
  selectCustomActionRequired,
  selectLoading,
} from "_store/orders/selector";
import { selectCustomerId } from "_store/user/selector";

import Button from "components/atomic/atoms/Button";
import Icon from "components/atomic/atoms/Icon";
import { SpinnerWrapper } from "components/atomic/atoms/Spinner";
import ActionExtraDescription from "components/atomic/organisms/sections/ActionDetails/ActionExtraDescription";
import ActionForm from "components/atomic/organisms/sections/ActionDetails/ActionForm";
import CardContent from "components/layouts/CardContent";

function modifyProductActionText(productCode, text, productCodes = []) {
  if (text && productCode === PRODUCT_CODES.incBoi) {
    const isEinIncluded = productCodes.includes(PRODUCT_CODES.incEIN);

    return isEinIncluded
      ? text
          ?.replace("Customer ID Needed", "Customer ID & Date of Birth Needed")
          .replace(
            "please upload a form of acceptable ID",
            "please provide and upload an acceptable form of ID & your Date of Birth"
          )
      : text
          ?.replace("Customer ID Needed", "Customer ID, Date of Birth & SSN Needed")
          .replace(
            "please upload a form of acceptable ID",
            "please provide and upload an acceptable form of ID, your Date of Birth & SSN"
          );
  }

  return text;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  box-sizing: border-box;
  width: 100%;
  height: 90%;
  max-height: 90%;
  animation: fadeIn 0.5s;

  ${({ theme }) => theme.animations.fadeIn}

  @media (max-width: 991px) {
    height: 100%;
    max-height: 100%;
  }
`;

const ContentRowHeader = styled.div`
  min-width: 100px;
  max-width: 100%;
  width: max-content;
  padding: 8px;
  font-weight: bold;
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
  border-top: 1px solid #eaecf0;
  border-radius: 8px 8px 0 0;
  /* box-shadow: 0px 0px 4px lightgray inset; */
  background-color: #eaecf0;

  ${({ theme }) =>
    css`
      color: ${theme.colors["cl-text-dark"]};
    `};
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
  /* border: 5px solid #eaecf0; */
  background-color: #eaecf0;
  /* box-shadow: 0px 0px 4px lightgray inset; */
  border-radius: 0 8px 8px 8px;
  padding: 8px;

  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const Space = styled.div`
  height: 64px;
  border-bottom: 1px solid white;
  opacity: 0%;
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  max-width: 300px;
  padding: 6px;
`;

const StyledContent = styled(CardContent)`
  flex-direction: row;
  gap: 32px;
  height: max-content;
  padding: 12px;

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

const ActionInfo = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  /* border-right: 1px solid rgb(219, 228, 240); */
  border-bottom: none;
  /* box-shadow: 0 0 6px 0 rgb(219, 228, 240) inset; */
  /* border: 1px solid rgb(219, 228, 240); */
  /* border-radius: 12px; */
  /* padding: 12px; */

  @media (max-width: 760px) {
    /* border-bottom: 1px solid rgb(219, 228, 240); */
    flex: 12;
    border-right: none;
    padding: 12px 0;
  }
`;
const ActionTitle = styled.div`
  border-bottom: 1px solid rgb(219, 228, 240);
  color: ${({ theme }) => theme.colors["cl-text-light"]};
`;

const ActionDescription = styled.div`
  color: ${({ theme }) => theme.colors["cl-text-dark"]};
  padding: 12px 0;
`;
const ActionDescParagraph = styled.p`
  margin-bottom: 8px;
`;
const QuickActions = styled.div`
  flex: 7;
  /* padding: 12px; */

  @media (max-width: 760px) {
    flex: 12;
    padding: 12px 0;
  }
`;
const QuickActionTitle = styled.div`
  border-bottom: 1px solid rgb(219, 228, 240);
  color: ${({ theme }) => theme.colors["cl-text-light"]};
`;
const QuickActionContent = styled.div`
  display: flex;
  gap: 12px;
  padding: 12px 0;
  color: ${({ theme }) => theme.colors["cl-text-dark"]};
`;

const ActionDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productId, type } = useParams();

  const [wasFormSubmitted, setWasFormSubmitted] = useState(false);

  const relatedProductCodes = useSelector(selectActiveOrderRelatedCodes);
  const actions = useSelector(selectActionRequiredProducts);
  const customActions = useSelector(selectCustomActionRequired);
  const customerId = useSelector(selectCustomerId);
  const paymentMethodsLoading = useSelector(selectPaymentMethodsLoading);
  const ordersLoading = useSelector(selectLoading);

  const loading = ordersLoading || paymentMethodsLoading;

  let currentAction = null;

  if (type === "ACTION") {
    currentAction = actions.unresolved?.find((action) => action?.product?.id === productId);
  } else {
    currentAction = customActions?.find((action) => action.type === type && action.shouldBeRendered);
  }

  // const order = currentAction?.order;
  const product = currentAction?.product;
  const status = product?.processingResult?.status;
  const processingError = product?.processingResult?.processingError;

  const actionRequired = currentAction?.actionRequired?.actionRequired;
  const customerActionDescription = currentAction?.actionRequired?.customerActionDescription;

  function renderActionRequiredTitle() {
    const code = processingError || status;

    switch (code) {
      case "CardDeclined":
        return `${actionRequired} For Your "${handleProductName(product?.customerName) || ""}" Service`;
      default:
        return actionRequired;
    }
  }

  function renderActionFormTitle() {
    if (status === "Error") {
      switch (processingError) {
        case "CardDeclined":
          return "Please Enter Payment Method to Re-attempt Charge";

        case "MismatchError":
          return "Please Enter Your Name To Verify";

        case "NameConflict":
          return "Alternative Names";

        case "WVInformationNeeded":
          return "Please answer the following questions:";

        default:
          return "Please, follow the necessary steps:";
      }
    } else {
      switch (status) {
        case "Rejected":
          return "Pick another name and re-file";

        default:
          return "Please, follow the necessary steps:";
      }
    }
  }

  const formProps = {
    status,
    error: processingError,
    type,
    loading,
    product,
    wasFormSubmitted,
    handleWasFormSubmitted: setWasFormSubmitted,
    handleCallback: () => {
      navigate("../actions");
    },
  };

  useEffect(() => {
    processingError === "CardDeclined" && customerId && dispatch(getPaymentMethods({ customerId }));
  }, [dispatch, customerId, processingError]);

  useEffect(() => {
    if (!loading && !currentAction) {
      navigate("../actions");
    }
  }, [currentAction]);
  return (
    <Container>
      <ContentRowHeader>
        <StyledButton disabled={loading} onClick={() => navigate("../actions")}>
          <Icon inline size="16px" icon="misc:back-arrow" />
          &nbsp;Go&nbsp;Back
        </StyledButton>
      </ContentRowHeader>
      <ContentRow>
        <StyledContent>
          <ActionInfo>
            <ActionTitle>{renderActionRequiredTitle(actionRequired, product?.code?.code)}</ActionTitle>
            <ActionDescription>
              {customerActionDescription
                ?.split("\n")
                ?.map(
                  (item, idx) =>
                    item && (
                      <ActionDescParagraph key={`action-paragraph-${idx}`}>
                        {modifyProductActionText(product?.code?.code, item, relatedProductCodes)}
                      </ActionDescParagraph>
                    )
                )}
              {!customerActionDescription && "This action don't have any description. Please, contact with our agents."}
            </ActionDescription>
            <ActionExtraDescription {...formProps} />
          </ActionInfo>
          <QuickActions>
            <QuickActionTitle>{renderActionFormTitle()}</QuickActionTitle>
            <QuickActionContent>
              <ActionForm {...formProps} />
            </QuickActionContent>
          </QuickActions>
        </StyledContent>
      </ContentRow>
      <Space />
      {loading && <SpinnerWrapper $dark />}
    </Container>
  );
};

export default ActionDetailsPage;
